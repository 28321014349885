import * as React from "react";
import { FC, useState } from "react";
import SpeakerModal from "./SpeakerModal";

export interface SessionSpeakerProps {
  id: number;
  avatar?: string;
  fullName: string;
  shortProfile: string;
}

const AVATAR_SIZE = 36;

const SessionSpeaker: FC<SessionSpeakerProps> = ({
  id,
  avatar,
  fullName,
  shortProfile,
}) => {
  const [modal, setModal] = useState<SessionSpeakerProps>(null);

  const closeModal = () => setModal(null);
  const openModal = () => setModal({ id, avatar, fullName, shortProfile });
  return (
    <>
      <SpeakerModal data={modal} onHide={closeModal} />
      <div
        className="speakerBtn d-flex align-items-center mb-2"
        onClick={openModal}
      >
        <span
          className="speaker-image mx-1 img-fluid rounded-circle"
          style={{
            backgroundImage: `url("${avatar}")`,
            width: `${AVATAR_SIZE}px`,
            height: `${AVATAR_SIZE}px`,
          }}
        />
        {/* <img
          src={avatar}
          className="rounded-circle me-2"
          height={AVATAR_SIZE}
          width={AVATAR_SIZE}
        /> */}
        <div>
          <small className="fw-bold">{fullName}</small>
        </div>
      </div>
    </>
  );
};

export default SessionSpeaker;
