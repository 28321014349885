import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import HallSessions from "./HallSessions";

export const DAY_ONE_HALLS: Array<ProgrammeSession["hall"]> = ["Pre-Congress"];
export const DAY_TWO_HALLS: Array<ProgrammeSession["hall"]> = ["Pre-Congress"];
export const DAY_THREE_HALLS: Array<ProgrammeSession["hall"]> = [
  "Hall 1",
  "Hall 2",
];
export const DAY_FOUR_HALLS: Array<ProgrammeSession["hall"]> = [
  "Hall 1",
  "Hall 2",
  "Hall 3",
];

const getEventDates = (locale: string) => [
  {
    dayNum: 1,
    dayName:
      locale === "cn"
        ? "3.11.2024，星期日，第一天（会前游览）"
        : "1st Day 3.11.2024 Sunday (Pre-Conference Tour)",
    // date: (
    //   <>
    //     6<sup>th</sup> Nov 2024
    //   </>
    // ),
  },
  {
    dayNum: 2,
    dayName:
      locale === "cn"
        ? "4.11.2024，星期一，第二天（会前游览）"
        : "2nd Day 4.11.2024 Monday (Pre-Conference Tour)",
    // date: (
    //   <>
    //     7<sup>th</sup> Nov 2024 (Thu)
    //   </>
    // ),
  },
  {
    dayNum: 3,
    dayName:
      locale === "cn"
        ? "5.11.2024，星期二，第三天 (主会议)"
        : "3rd Day 5.11.2024 (Tuesday)",
    // date: (
    //   <>
    //     8<sup>th</sup> Nov 2024 (Fri)
    //   </>
    // ),
  },
  {
    dayNum: 4,
    dayName:
      locale === "cn"
        ? "6.11.2024，星期三，第四天 (主会议)"
        : "4th Day 6.11.2024 (Wednesday)",
    // date: (
    //   <>
    //     9<sup>th</sup> Nov 2024 (Sat)
    //   </>
    // ),
  },
];

export const FACULTY_TYPE = [
  "Chairperson",
  "Citation",
  "Co-Chair",
  "Emcee",
  "Judge",
  "Guest Of Honor",
  "Speaker",
  "Adjudicator",
  "Moderator",
] as const;

// export enum CATEGORY {
//   "asad_1",
//   "asad_2",
//   "msc",
//   "msn",
//   "ccndp",
//   "bnc",
//   "allied_health",
// }

export interface Faculty {
  id: number;
  fullName: string;
  avatar: string;
  shortProfile: string;
}

export interface FacultyWithType extends Faculty {
  facultyType: (typeof FACULTY_TYPE)[number];
}

export interface ProgrammeSession {
  id: number;
  title: string;
  desc?: string;
  startAt: string;
  endAt: string;
  date: string; // 11 JUN | SATURDAY
  time: string; //10:00 to 11:00
  day: 1 | 2 | 3 | 4;
  hallOverwrite?: string;
  hall: "Pre-Congress" | "Hall 1" | "Hall 2" | "Hall 3";
  hallLabel: string;
  locale: string;
  // category:
  //   | "asad_1"
  //   | "asad_2"
  //   | "msc"
  //   | "msn"
  //   | "ccndp"
  //   | "bnc"
  //   | "allied_health";
  faculties: Array<FacultyWithType>;
  subsessions: Array<ProgrammeSubsession>;
  secondsTillStart: number;
  durationInSeconds: number;
  ended?: boolean;
}

export interface ProgrammeSubsession {
  id: number;
  title?: string;
  description?: string;
  speakers: Array<Faculty>;
  time: string; // 10:00 to 11:00
}

interface ProgrammeProps {
  defaultDay: 1 | 2 | 3 | 4;
  locale: string;
}

const Programme = ({ defaultDay, locale }: ProgrammeProps) => {
  const [loading, setLoading] = useState(false);
  const [day, setDay] = useState(1);
  const [data, setData] = useState([]);

  const [activeLecture, setActiveLecture] =
    useState<ProgrammeSession["hall"]>("Pre-Congress");

  const EVENT_DATES = getEventDates(locale);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/programme_sessions?day=${day}`)
      .then((response) => {
        setData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [day]);

  const getTabColor = (hallname: string) => {
    switch (hallname) {
      case "Pre-Congress":
        return "#fbe300";
      case "Hall 1":
        return "#e8e8e8";
      case "Hall 2":
        return "#02d7f3";
      case "Hall 3":
        return "#f9a826";
      case "会前游览":
        return "#fbe300";
      case "大会厅1":
        return "#e8e8e8";
      case "大会厅2":
        return "#02d7f3";
      case "大会厅3":
        return "#f9a826";

      default:
        return "#7f7f7f";
    }
  };

  const getHallLabelCn = (hallname: string) => {
    switch (hallname) {
      case "Pre-Congress":
        return "会前游览";
      case "Hall 1":
        return "大会厅1";
      case "Hall 2":
        return "大会厅2";
      case "Hall 3":
        return "大会厅3";

      default:
        return hallname;
    }
  };

  const getHallLabelEn = (hallname: string) => {
    switch (hallname) {
      case "Pre-Congress":
        return "Pre-Conference";
      case "Hall 1":
        return "Hall 1";
      case "Hall 2":
        return "Hall 2";
      case "Hall 3":
        return "Hall 3";

      default:
        return hallname;
    }
  };

  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    );
  }

  const dayClass = "btn-custom my-1 fw-bold me-2";
  const catClass = "btn-custom-orange my-1 fw-bold me-2";

  return (
    <div className="container py-3 py-lg-4" id="programmeAgenda">
      {/* <div className="filterBtns d-flex flex-column align-items-center"> */}
      <ul id="programme-tabs" className="nav nav-tabs justify-content-center">
        {/* 1st row */}
        {EVENT_DATES.map(({ dayNum, dayName }) => (
          <li className="nav-item d-flex flex-column h-100" key={dayNum}>
            <button
              id={dayName}
              className={`${dayNum === day && "active"} nav-link flex-grow-1`}
              // className={`${dayNum === day && "dark"} ${dayClass}`}
              onClick={() => {
                setDay(dayNum);
                setActiveLecture(
                  dayNum === 1 || dayNum === 2 ? "Pre-Congress" : "Hall 1"
                );
              }}
            >
              <h5>{dayName}</h5>
            </button>
          </li>
        ))}

        {/* 2nd row */}
        {/* {(day === 1 || day === 2 || day === 3) && (
          <div className="my-3 text-center" style={{ maxWidth: "800px" }}>
            <button
              className={`${
                category === CATEGORY.asad_1 && "dark"
              } ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.asad_1);
                setActiveLecture("ASAD 1 Track");
              }}
            >
              ASAD Track
            </button>
            <button
              className={`${category === CATEGORY.msc && "dark"} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.msc);
                setActiveLecture("MSC Track");
              }}
            >
              MSC Track
            </button>
            <button
              className={`${category === CATEGORY.msn && "dark"} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.msn);
                setActiveLecture("MSN Track");
              }}
            >
              MSN Track
            </button>
            <button
              className={`${category === CATEGORY.ccndp && "dark"} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.ccndp);
                setActiveLecture("CCNDP Track");
              }}
            >
              CCNDP Track
            </button>
            <button
              className={`${category === CATEGORY.bnc && "dark"} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.bnc);
                setActiveLecture("BNC Track");
              }}
            >
              BNC Track
            </button>
            <button
              className={`${
                category === CATEGORY.allied_health && "dark"
              } ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.allied_health);
                setActiveLecture("Allied Health Track");
              }}
            >
              Allied Health Track
            </button>
          </div>
        )} */}
      </ul>

      {/* 3rd row */}
      <div className="tab-content">
        {day === EVENT_DATES[0].dayNum && (
          <div className="mt-5">
            <div className="d-block d-lg-none">
              {DAY_ONE_HALLS.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && "deselected"
                  }`}
                  style={{
                    background: getTabColor(hall),
                    textTransform: "capitalize",
                  }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {/* {hall} */}
                  {locale === "cn"
                    ? getHallLabelCn(hall)
                    : getHallLabelEn(hall)}
                </button>
              ))}
            </div>

            <div className="row justify-content-center gy-4 nogutters">
              {DAY_ONE_HALLS.map((hall) => (
                // <div className={day === 2 ? "col-lg-6" : "col-lg-4 px-2"} key={hall}>
                <div className="col-lg-6" key={hall}>
                  <HallSessions
                    hallName={hall}
                    hallLabel={
                      locale === "cn"
                        ? getHallLabelCn(hall)
                        : getHallLabelEn(hall)
                    }
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {day === EVENT_DATES[1].dayNum && (
          <div className="mt-5">
            <div className="d-block d-lg-none">
              {DAY_TWO_HALLS.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && "deselected"
                  }`}
                  style={{
                    background: getTabColor(hall),
                    textTransform: "capitalize",
                  }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {/* {hall} */}
                  {locale === "cn"
                    ? getHallLabelCn(hall)
                    : getHallLabelEn(hall)}
                </button>
              ))}
            </div>

            <div className="row justify-content-center gy-4 nogutters">
              {DAY_TWO_HALLS.map((hall) => (
                // <div className={day === 2 ? "col-lg-6" : "col-lg-4 px-2"} key={hall}>
                <div className="col-lg-6" key={hall}>
                  <HallSessions
                    hallName={hall}
                    hallLabel={
                      locale === "cn"
                        ? getHallLabelCn(hall)
                        : getHallLabelEn(hall)
                    }
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {day === EVENT_DATES[2].dayNum && (
          <div className="mt-5">
            <div className="d-block d-lg-none">
              {DAY_THREE_HALLS.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && "deselected"
                  }`}
                  style={{
                    background: getTabColor(hall),
                    textTransform: "capitalize",
                  }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {/* {hall} */}
                  {locale === "cn"
                    ? getHallLabelCn(hall)
                    : getHallLabelEn(hall)}
                </button>
              ))}
            </div>

            <div className="row justify-content-center gy-4 nogutters">
              {DAY_THREE_HALLS.map((hall) => (
                // <div className={day === 2 ? "col-lg-6" : "col-lg-4 px-2"} key={hall}>
                <div className="col-lg-6" key={hall}>
                  <HallSessions
                    hallName={hall}
                    hallLabel={
                      locale === "cn"
                        ? getHallLabelCn(hall)
                        : getHallLabelEn(hall)
                    }
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {day === EVENT_DATES[3].dayNum && (
          <div className="mt-5">
            <div className="d-block d-lg-none">
              {DAY_FOUR_HALLS.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && "deselected"
                  }`}
                  style={{
                    background: getTabColor(hall),
                    textTransform: "capitalize",
                  }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {/* {hall} */}
                  {locale === "cn"
                    ? getHallLabelCn(hall)
                    : getHallLabelEn(hall)}
                </button>
              ))}
            </div>

            <div className="row justify-content-center gy-4 nogutters">
              {DAY_FOUR_HALLS.map((hall) => (
                // <div className={day === 2 ? "col-lg-6" : "col-lg-4 px-2"} key={hall}>
                <div className="col-lg-4" key={hall}>
                  <HallSessions
                    hallName={hall}
                    hallLabel={
                      locale === "cn"
                        ? getHallLabelCn(hall)
                        : getHallLabelEn(hall)
                    }
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Programme;
